import type {
  CSSProperties,
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  FC,
} from "react";
import { Icon, Inline, Text } from "~/components/vendorUI";
import { forwardRef, useState, useEffect, useRef, useCallback } from "react";
import { ScrollSnapSlider } from "./ScrollSnapSlider";
import {
  NavigationKeyCss,
  NavigationContainerCss,
  slideCss,
  sliderCss,
  SlideIndicatorText,
} from "~/components/Slider/styles/SliderStyles";

import { cx } from "ui/css";
import { useTranslation } from "~/utils";

type SliderProps = ComponentPropsWithoutRef<"ul"> & {
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  onSlideStart?: (slideIndex: number) => void;
  onSlidePass?: (slideIndex: number) => void;
  onSlideStop?: (slideIndex: number) => void;
  isLoading?: boolean;
};

const Root: FC<SliderProps> = ({
  width,
  height,
  className = "",
  isLoading = false,
  style = {},
  onSlideStart,
  onSlidePass,
  onSlideStop,
  ...props
}) => {
  const ref = useRef<HTMLUListElement>(null);
  const [sliderObj, setSliderObj] = useState<ScrollSnapSlider | null>(null);
  const [active, setActive] = useState<number>(0);
  const slidesCount = sliderObj?.element.childNodes.length || 0;
  const { t } = useTranslation();

  //if ever required
  // const slideTo = (index: number) =>
  //   sliderObj && 0 <= index && index < slidesCount && sliderObj.slideTo(index);

  const slideLeft = () =>
    sliderObj && 0 <= active - 1 && sliderObj.slideTo(active - 1);

  const slideRight = () =>
    sliderObj && active - 1 < slidesCount && sliderObj.slideTo(active + 1);

  const handleSlideStart = useCallback(
    (event: Event) => {
      const customEvent = event as CustomEvent<number>;
      if (typeof onSlideStart === "function") onSlideStart(customEvent.detail);
    },
    [onSlideStart],
  );

  const handleSlidePass = useCallback(
    (event: Event) => {
      const customEvent = event as CustomEvent<number>;
      setActive(customEvent.detail);
      if (typeof onSlidePass === "function") onSlidePass(customEvent.detail);
    },
    [onSlidePass],
  );

  const handleSlideStop = useCallback(
    (event: Event) => {
      const customEvent = event as CustomEvent<number>;
      setActive(customEvent.detail);
      if (typeof onSlideStop === "function") onSlideStop(customEvent.detail);
    },
    [onSlideStop],
  );

  const leftKeyDisabled = active === 0;
  const rightKeyDisabled = active === slidesCount - 1;
  const disbaled = !slidesCount;
  useEffect(() => {
    if (ref.current) {
      const slider = new ScrollSnapSlider({ element: ref.current });

      setSliderObj(slider);

      slider.addEventListener("slide-start", handleSlideStart);
      slider.addEventListener("slide-pass", handleSlidePass);
      slider.addEventListener("slide-stop", handleSlideStop);

      return () => {
        slider.removeEventListener("slide-start", handleSlideStart);
        slider.removeEventListener("slide-pass", handleSlidePass);
        slider.removeEventListener("slide-stop", handleSlideStop);
      };
    }
  }, [handleSlidePass, handleSlideStart, handleSlideStop]);

  return (
    <>
      <ul
        className={cx(sliderCss, className)}
        style={{ ...style, width, height }}
        {...props}
        ref={ref}
      />
      {sliderObj && (
        <Inline
          distribute="between"
          align="center"
          className={NavigationContainerCss}
          key={slidesCount}
        >
          <Text
            color="secondary"
            className={SlideIndicatorText({
              status: disbaled ? "disabled" : "enabled",
            })}
            size="14px"
            data-testid="slider-status"
          >
            {t("GROWTH-RECOMMENDATION-SLIDER-STATUS", {
              active: slidesCount ? active + 1 : 0,
              slides: slidesCount,
            })}
          </Text>
          <Inline gap="8px">
            <button
              className={NavigationKeyCss({ direction: "left" })}
              onClick={slideLeft}
              disabled={leftKeyDisabled}
              data-testid="slider-left-key"
            >
              <Icon
                color={leftKeyDisabled ? "lightGrey" : "sky"}
                name="NewChevronDown"
              />
            </button>
            <button
              className={NavigationKeyCss({ direction: "left" })}
              onClick={slideRight}
              disabled={rightKeyDisabled}
              data-testid="slider-right-key"
            >
              <Icon
                color={rightKeyDisabled ? "lightGrey" : "sky"}
                name="NewChevronUp"
              />
            </button>
          </Inline>
        </Inline>
      )}
    </>
  );
};

const Slide: FC<ComponentPropsWithRef<"li">> = forwardRef(
  ({ className = "", ...props }, ref) => (
    <li className={cx(slideCss, className)} {...props} ref={ref} />
  ),
);

export const Slider = Object.assign(Root, {
  Slide,
});

Slider.displayName = "Slider";
Slide.displayName = "Slide";
