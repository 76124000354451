export const path = {
  NOT_FOUND: "/404",
  CLICKS: "/clicks",
  SERVICE_AGREEMENT: "/ppl/service_agreement",
  PAYMENT_DETAILS: "/ppl/payment-details",
  REFUNDS: "/ppl/refunds",
  PPL: "/ppl",
  REVIEWS_GATHER_REVIEWS: "/reviews/gather_reviews",
  REVIEWS_SHARE_YOUR_REVIEW_FORM: "/reviews/share_your_review_form",
  REVIEWS_BADGE_CENTER: "/reviews/badge_center",
  REVIEWS_BADGES_MANAGEMENT: "/reviews/badges-management",
  REVIEWS_REVIEW_QUOTES: "/reviews/review_quotes",
  REVIEWS: "/reviews",
  REVIEWS_DETAIL: "/reviews/:reviewId",
  PPC_PAYMENT_DETAILS: "/ppc/payment-details",
  PPC_MANAGE_BIDS: "/ppc/manage-bids",
  BIDS_LANDING_PAGES: "/bids/landing_pages",
  MY_PROFILE: "/my_profile",
};
